<template>
  <v-layout v-if="campaignLandingPageConfig">
    <div class="v-window-head">
      <div class="title">
        <div class="title-text">
          <h2>Resumo</h2>
          <div class="dot" 
            style="background: rgba(var(--theme-primaria));"></div>
        </div>
      </div>

      <div class="box fluid">
        <div class="box-section"
          
        >
          <div class="box-title" v-if="campaignDonationProduct">
            <h3>{{ campaignDonationProduct ? campaignDonationProduct.name : '' }}</h3>
            <i class="fas fa-info-circle primary" style="color: rgba(var(--theme-primaria))"></i>
          </div>

          <div class="box-item-resume extra" v-if="selectedGift">
            <i class="fal fa-level-up fa-rotate-90 light big"></i>
            <div class="item-name">
              <span style="color: rgba(var(--theme-primaria))">
                Agradecimento adicional
              </span>
              <p>{{ selectedGift.name }}</p>
            </div>
          </div>
        </div>

        <div class="box-item-resume"
          v-for="item of itemsToCheckout"
          :key="'item'+item.id"
        >
          <div class="item-name border">
            <span style="color: rgba(var(--theme-primaria))">
              {{ item.project.institution.entity.name }}
            </span>
            <p>{{ item.project.shortName }}</p>
          </div>

          <div class="item-value">
            <span>R$ </span>
            <p>{{ formatterHelper.formatMoneyWithCents(item.itemValue).replace('R$','') }}</p>
          </div>
        </div>

        <div class="value-total">
          <caption>
            Valor total da doação
          </caption>
          <p style="color: rgba(var(--theme-primaria))">
            {{ formatterHelper.formatMoneyWithCents(checkoutValue) }}
          </p>
        </div>
      </div>
    </div>

    <div class="v-window-body">
      <div class="title">
        <div class="title-text">
          <h2>Com que frequência deseja doar?</h2>
          <div class="dot" 
            style="background: rgba(var(--theme-primaria));"></div>
        </div>
      </div>
      <div class="custom-radio-group">
        <div v-for="recurrence in availableRecurrency" :key="recurrence.donationRecurrencePeriodId">
          <label class="radio"
            :style="recurrency == recurrence.donationRecurrencePeriodId ? {'background-color': 'rgba(var(--theme-primaria)) !important'} : {}"
            >
            {{ getRecurrenceName(recurrence.donationRecurrencePeriodId) }}
              <input
                type="radio"
                name="recurrence-period"
                :id="recurrence.donationRecurrencePeriodId"
                :value="recurrence.donationRecurrencePeriodId"
                v-model="recurrency"
              />
            </label>
        </div>
        <!-- <label class="radio"
        :style="recurrency == 'monthly' ? {'background-color': 'rgba(var(--theme-primaria)) !important'} : {}"
        >
          Todo mês
          <input
            type="radio"
            id="monthly"
            name="recurrency"
            value="monthly"
            checked
            v-model="recurrency"
          />
        </label>

        <label class="radio"
        :style="recurrency == 'once' ? {'background-color': 'rgba(var(--theme-primaria)) !important'} : {}"
        >
          Uma vez só
          <input
            type="radio"
            id="once"
            name="recurrency"
            value="once"
            checked
            v-model="recurrency"
          />
        </label> -->
      </div>

      <div class="title top-24">
        <div class="title-text">
          <h2>Forma de pagamento</h2>
          <div class="dot" 
            style="background: rgba(var(--theme-primaria));"></div>
        </div>
      </div>

      <!-- Payment methods -->
        <div
          class="w-100"
          v-for="item of paymentsOrder"
          :key="`payment-method_${item.name}`"
        >
          <slot :name="(item.name).toLowerCase()"></slot>
        </div>
      </div>
    
  </v-layout>
</template>

<script>
// VUEX
import { mapGetters, mapActions } from "vuex";

// Services
import CampaignService from "@/scripts/services/campaign.service";
import PaymentInstallmentService from "@/scripts/services/paymentInstallment.service";
import cartStorageService from "@/scripts/services/cartStorage.service";
import CompanyService from "@/scripts/services/company.service";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

// Enums
import donationRecurrencePeriod from "@/scripts/models/enums/donationRecurrencePeriod.enum"
import paymentMethod from "@/scripts/models/enums/paymentMethod.enum"

export default {
  props: {
    //paymentMethods: Array,
  },
  data() {
    return {
      campaignService: new CampaignService(),
      paymentInstallmentService: new PaymentInstallmentService(),
      companyService: new CompanyService(),
      formatterHelper: new FormatterHelper(),
      paymentMethods: [],
      paymentObject: null,
    };
  },
  watch: {
    checkoutValue: {
      handler: async function(val) {
        if (val)
          await this.availablePaymentMethods();
      }
    }
  },
  computed: {
    ...mapGetters([
        "itemsToCheckout",
        "checkoutValue",
        "selectedGift",
        "campaignDonationProduct",
        "campaignLandingPageConfig",
        "installments",
        "paymentInstallments",
    ]),
    paymentsOrder() {
      return Object.values(this.paymentInstallments).sort((a, b) => a.order - b.order);
    },
    recurrency: {
      get() {
        const hasDefaultRecurrency = (this.availableRecurrency.findIndex(x => x.donationRecurrencePeriodId === this.$store.getters.recurrency) !== -1);
        if (hasDefaultRecurrency) {
          return this.$store.getters.recurrency
        }  else {
          this.setRecurrency(this.availableRecurrency[0].donationRecurrencePeriodId)
          return this.availableRecurrency[0].donationRecurrencePeriodId
        }
      },
      set(value) {
        this.setRecurrency(value)
      }
    },
    availableRecurrency() {
      return this.campaignLandingPageConfig.campaign.campaignDonationRecurrencePeriodList;
    },
  },
  methods: {
    ...mapActions([
      "setRecurrency",
      "setPaymentInstallments"
    ]),
    async availablePaymentMethods() {
      const pMethods = await this.campaignService.getAllPaymentMethods();
      const cart = cartStorageService.getCart();

      pMethods.forEach(async (item) => {
       // item.installmentInfo = await this.getInstallments(item.id);

        const installment = {
          ...item,
          installmentInfo: await this.getInstallments(item.id),
          order: this.getOrder((item.name))
        }

        if (item.name === "PayrollDeduction" && cart.companyId !== null) {
          this.companyService.find((data) => {
            installment.companyName = data.entity.name;
            installment.companyCnpj = data.entity.cnpj;
          },cart.companyId);
        }

        this.paymentMethods[(installment.name).toLowerCase()] = installment;
        this.setPaymentInstallments(this.paymentMethods);
      });
    },
    getOrder(paymentMethodName) {
      if (paymentMethodName === "PayrollDeduction") {
          return 1;
        }
        if (paymentMethodName === "Ticket") {
          return 4;
        }
        if (paymentMethodName === "DebitAccount") {
          return 5;
        }
        if (paymentMethodName === "Pix") {
          return 2;
        }
        if (paymentMethodName === "CreditCard") {
          return 3;
        }
    },
    async getInstallments(paymentMethodId) {
      return await this.paymentInstallmentService.getFirstInstallments(
        this.campaignLandingPageConfig.campaignId,
        this.checkoutValue,
        paymentMethodId,
        true
      );
    },
    getParcelamentNumber(paymentMethodId) {
      const parcelamentList = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList
      const paymentParcelament = parcelamentList.filter(item => item.paymentMethodId === paymentMethodId)
      return paymentParcelament[0].parcelament;
    },
    getObjectValue(indexValue) {
      let counter = 0
      for (const object in donationRecurrencePeriod) {
        if (counter === indexValue) return object
        counter++
      }
    },
    getRecurrenceName(recurrenceId) {
      if (recurrenceId === 1) return "Única"
      if (recurrenceId === 2) return "Todo mês"
      if (recurrenceId === 3) return "Bimestral"
      if (recurrenceId === 4) return "Trimestral"
      if (recurrenceId === 5) return "Quadrimestral"
      if (recurrenceId === 6) return "Semestral"
      if (recurrenceId === 7) return "Anual"
    }
  }
};
</script>
