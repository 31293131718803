<template>
  <div class="container-fluid w-100 mt-5" :class="isModal ? 'aux-cfluid' : ''">
    <v-flex v-if="isModal" display-flex flex-column mb-3 align-center justify-center>
      <h3 class="mt-3 mb-2 text-xs-center f-size-32 color-theme-texto font-destaque" v-html="campaignLandingPageConfig ? campaignLandingPageConfig.donationProductsTitle : ''">
      </h3>
    </v-flex>
    <div class="wrapper pb-3">
      <v-layout wrap display-flex justify-center>
        <template v-if="!isModal">
          <button type="button" class="scroll-arrow prev" id="agradPrevArrow">
            <Icon name="far fa-chevron-left" size="16" />
          </button>
          <button type="button" class="scroll-arrow next" id="agradNextArrow">
            <Icon name="far fa-chevron-right" size="16" />
          </button>
          <slick class="bg-white" ref="donationProductsSlick" id="donationProductWrapper"
            :options="slickDonationProductsOptions">
            <div v-for="donationProduct in donationProductList" :key="donationProduct.id" class="item">
              <div class="projeto">
                <div class="box-donation-product" :key="donationProduct.id">
                  <div>
                    <img :src="donationProduct.file.path" alt />
                    <h2 class="color-theme-primaria w-100 notranslate">
                      {{ donationProduct.name }}
                    </h2>
                    <h3 class="color-theme-texto notranslate">
                      {{ FormatterHelper.formatMoney(donationProduct.fixedValue, 2) }}
                    </h3>
                    <p class="notranslate" v-html="donationProduct.description"></p>
                  </div>
                  <div>
                    <button
                      v-if="showDonationButton"
                      type="button"
                      :class="campaignDonationProductAlreadyOnCart(donationProduct.id)
                        ? 'with-check'
                        : ''
                      "
                      value="FormatterHelper.formatMoney(donationProduct.fixedValue, 2) : donationProduct.suggestionValue}}"
                      class="bg-theme-primaria px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                      @click="
                        $gtag(
                          'send',
                          'event',
                          'projetos carrossel',
                          'Quero doar',
                          donationProduct.suggestionValue
                        );
                      "
                    >
                      <Icon name="fas fa-check" class="check-circle" size="18" colorFont="#02ACB4"></Icon>
                      {{
                        campaignLandingPage
                          ? campaignLandingPage.donationButtonText
                          : "Quero doar"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </slick>
        </template>
        <template v-else>
          <div
            class="box-donation-product"
            v-for="donationProduct in donationProductList.filter((x) => x.active)"
            :key="donationProduct.id"
          >
            <div v-if="donationProduct.active">
              <img :src="donationProduct.file.path" alt />
              <h2 id="donation-product-name" class="color-theme-primaria">
                {{ donationProduct.name }}
              </h2>
              <h3 class="color-theme-texto notranslate" v-if="donationProduct.finalValue !== '0'">
                De {{ FormatterHelper.formatMoneyWithCents(donationProduct.initialValue, 2) }}
                a {{ FormatterHelper.formatMoneyWithCents(donationProduct.finalValue, 2) }}
              </h3>
              <h3 class="color-theme-texto notranslate" v-else>
                Mais de {{ FormatterHelper.formatMoneyWithCents(donationProduct.initialValue, 2) }}
              </h3>
              <p class="notranslate" v-html="donationProduct.description"></p>
            </div>
            <div>
              <button
                v-if="showDonationButton"
                type="button"
                :class="
                  campaignDonationProductAlreadyOnCart(donationProduct.id)
                    ? 'with-check'
                    : ''
                "
                class="bg-theme-primaria px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                @click="
                  $gtag(
                    'send',
                    'event',
                    'Produto de doação',
                    'Produto de doação',
                    'Botão quero doar'
                  );
                  addCartItem(donationProduct);
                "
              >
                <Icon name="fas fa-check" class="check-circle" size="18" colorFont="#02ACB4"></Icon>
                {{
                  campaignLandingPage
                    ? campaignLandingPage.donationButtonText
                    : "Quero doar"
                }}
              </button>
            </div>
          </div>
        </template>
      </v-layout>
    </div>
  </div>
</template>
<script type="plain/text">
import CampaignService from "@/scripts/services/campaign.service"
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import DonationProduct from "@/scripts/models/donationProduct.model";
import Project from "@/scripts/models/project.model";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    donationProductList: [DonationProduct],
    project: { type: Project, default: new Project() },
    showDonationButton: Boolean,
    isModal: Boolean,
  },
  data() {
    return {
      campaignService: new CampaignService(),
      selectedDonationProduct: null,
      ProjectTypes: ProjectTypes,
      FormatterHelper: new FormatterHelper(),
      coinSelected: "R$ ",
      projectList: [],
      slickDonationProductsOptions: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        prevArrow: "#agradPrevArrow",
        nextArrow: "#agradNextArrow",
        responsive: [
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 730,
            settings: {
              slidesToShow: 1,
              arrows: false,
              centerMode: true,
              centerPadding: "30px",
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "campaignLandingPageConfig",
      "campaignDonationProductAlreadyOnCart",
      "itemToAdd"
    ]),
  },
  async created() {
    if (!this.campaignLandingPageConfig?.id)
      await this.setLandingPageConfig()

    this.campaignService
      .getCampaignProjectList(this.campaignLandingPageConfig.campaign.id)
      .then((projectList) => {
        this.projectList = projectList
      });

    if (this.campaignLandingPageConfig?.campaign?.allowUsdDonation)
      this.coinSelected = "US$ ";

    this.showDonationButton = true;
    this.setItemToAdd(null);
  },
  methods: {
    ...mapActions([
      "alterCampaignDonationProduct",
      "addToCart",
      "setItemToAdd",
      "setLandingPageConfig"
    ]),
    openConfirmDonationToDonationProductModal(item) {
      this.addCampaignDonationProductToCart(item);
      this.end();
    },
    addCampaignDonationProductToCart(donationProduct) {
      this.alterCampaignDonationProduct(donationProduct.id)
    },
    end() {
      if (this.isModal) this.$emit("close");

      if (this.itemToAdd) {
        document.getElementById("animation-proj").classList.add("active");
        setTimeout(() => {
          document.getElementById("animation-proj").classList.remove("active");
        }, 2000);
      }

      this.setItemToAdd(null);
    },
    addCartItem(campaignProduct = null) {
      if (!this.itemToAdd) return this.openConfirmDonationToDonationProductModal(campaignProduct);

      this.addToCart({
        project: this.itemToAdd,
        // donationProduct: campaignProduct,
        campaignProduct: campaignProduct
      });

      this.end();      
    },
  },
};
</script>