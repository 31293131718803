<template>
  <CheckoutWrapper ref="checkoutWrapperRef" :tabs="computedTabs" :config="config" @goNext="goNext"
    style="color: rgba(var(--theme-primaria))" v-if="logged">

    <template #donation>
      <Donation />
    </template>
    
    <template #bonus>
      <Bonus @next="$refs.checkoutWrapperRef.goNext()" />
    </template>

    <template #acknowledgement>
      <Acknowledgement />
    </template>

    <template #resume>
      <Resume>
        
        <template #payrolldeduction>
          <Payroll 
            v-if="paymentInstallments.payrolldeduction"
            :params="paymentInstallments.payrolldeduction"
            @redirect="paymentRedirect($event)"      
            v-model="activePaymentMethod"
            />
        </template>
        <template #pix>
          <Pix 
            v-if="paymentInstallments.pix"
            :params="paymentInstallments.pix"
            @redirect="paymentRedirect($event)"
            :open-panel="openPanel" @set-open-panel="setOpenPanel"
            v-model="activePaymentMethod"
            />
        </template>

        <template #creditcard>
          <CreditCard v-if="paymentInstallments.creditcard" @redirect="paymentRedirect($event)"
            :params="paymentInstallments.creditcard" :open-panel="openPanel" @set-open-panel="setOpenPanel" 
            v-model="activePaymentMethod"
            />
        </template>

        <template #ticket>
          <Boleto v-if="paymentInstallments.ticket" @redirect="paymentRedirect($event)" 
            :params="paymentInstallments.ticket"
            :open-panel="openPanel" @set-open-panel="setOpenPanel" 
            v-model="activePaymentMethod"
            />
        </template>

        <template #debitaccount>
          <Debit v-if="paymentInstallments.debitaccount" @redirect="paymentRedirect($event)"
            :params="paymentInstallments.debitaccount" :open-panel="openPanel" @set-open-panel="setOpenPanel"
            v-model="activePaymentMethod"
            />
        </template>
      </Resume>
      <ConsentTerm />
    </template>


  </CheckoutWrapper>
</template>

<script>
// VUEX
import { mapGetters, mapActions } from "vuex";

// Checkout Core Slots
import Donation from "./Donation.vue";
import Acknowledgement from "./Acknowledgement.vue";
import Bonus from "./Bonus.vue";
import Resume from "./Resume.vue";
import CheckoutWrapper from "./CheckoutWrapper.vue";

// Checkout Payment Slots
import Boleto from "./Payment/Boleto.vue";
import CreditCard from "./Payment/CreditCard.vue";
import Payroll from "./Payment/Payroll.vue";
import Debit from "./Payment/Debit.vue";
import Pix from "./Payment/Pix.vue";

import { Routes } from "@/scripts/models/enums/routes.enum.js";

// Template
import ConsentTerm from "./ConsentTerm.vue";

// Services
import CampaignService from "@/scripts/services/campaign.service";
import CartService from "@/scripts/services/cart.service"

// Enums
import PaymentMethod from "../../../scripts/models/enums/paymentMethod.enum";

export default {
  components: {
    ConsentTerm,
    Donation,
    Acknowledgement,
    Resume,
    Bonus,
    CheckoutWrapper,

    Boleto,
    CreditCard,
    Payroll,
    Debit,
    Pix,
  },
  data() {
    return {
      openPanel: null,
      campaignService: new CampaignService(),
      cartService: new CartService(),
      PaymentMethod: PaymentMethod,
      paymentMethods: {},
      activePaymentMethod: null,
      config: {
        bonus: true,//this.isInMaintenanceValue && this.isInMaintenanceValue.isInMaintenanceValue,
        before: "acknowledgement", // Antes de qual tab o bonus vai aparecer
      },

      tabs: {
        donation: {
          title: "Valor",
          icon: "dollar-sign",
          active: true,
        },
        // bonus: {
        //   title: "Bonus",
        //   icon: "credit-card",
        //   active: true,
        // },
        acknowledgement: {
          title: "Agradecimento",
          icon: "hand-holding-heart",
          active: this.activeAcknowledgement(),
        },
        resume: {
          title: "Pagamento",
          icon: "credit-card",
          active: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "checkoutValue",
      "isInMaintenanceValue",
      "cartLocal",
      "cartItems",
      "logged",
      "reloadCart",
      "mergedCart",
      "paymentInstallments",
    ]),
    computedTabs() {
      return Object.keys(this.tabs)
        .map((slot) => ({
          slot,
          ...this.tabs[slot],
        }))
        .filter((tab) => !!tab.active);
    },
  },
  watch: {
    isInMaintenanceValue(val) {
      //this.config.bonus = val.isInMaintenanceValue
    },
    logged(newValue, oldValue) {
      if (this.reloadCart || ((oldValue == false || oldValue === null) && newValue)) {
        this.setMergedCart(false)
        this.saveCartitems();
      }
    },
  },
  async created() {
    await this.getProfile();
    this.refreshCart()
  },
  methods: {
    ...mapActions(["getProfile", "setReloadCart", "updateCart", "setMergedCart", "refreshCart"]),
    goNext() {
      this.setOpenPanel(null);
    },
    setOpenPanel(panel) {
      this.openPanel = panel;
    },
    getPaymentIndex(name){
      return this.paymentMethods.findIndex(x => x.name === name)
    },
    activeAcknowledgement() {
      return this.$store.getters.cartLocal.campaignProductId !== null || this.$store.getters.campaignProductId !== undefined
    },
    saveCartitems() {
      if (this.reloadCart) this.setReloadCart(false);
      let items = this.cartItems.map(item => {
        if (item.donationProduct) {
          return {
            projectId: item.project.id,
            donationProductId: item.donationProduct.id
          };
        } else {
          return {
            projectId: item.project.id
          };
        }
      })
      this.cartService.mergeCartItems(items).then((data) => {
        // Add campaign product if exist
        if (this.$store.getters.cartLocal && this.$store.getters.cartLocal.campaignProduct) {
          data.campaignProduct = this.cartLocal.campaignProduct
          data.campaignProductId = this.cartLocal.campaignProductId
        }

        if (data && data.cartItems) {
          this.updateCart(data);
          this.setMergedCart(true)
        }
      });
    },
    paymentRedirect(payment) {
      if (payment) {
        this.$router.push(Routes.web.OrderApproved);
      }
      this.$router.push({
        name: Routes.web.OrderApproved,
        query: { method: payment.method },
        params: { data: payment.data },
      });
    },
  },
};
</script>
